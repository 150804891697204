import React from 'react';
import { GoLinkExternal } from 'react-icons/go';

function ProjectItem({ name, desc, link, img }) {
    return (
        <React.Fragment>
            <h3 className='project-title'>{name}</h3>
            <div className='project-item'>
                <div className='project-img'>
                    <img 
                        src={img} 
                        alt={name} />
                </div>
                <div className='project-desc'>
                    <p>{desc}</p>
                    <a 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className='project-link' 
                        href={link}>
                            Visit Project <GoLinkExternal />
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProjectItem;