import readable from '../file/readable.png';
import fullstacknd from '../file/fullstacknd.png';
import reversi from '../file/reversi.png';
import mars from '../file/mars-dashboard.png';
import twitterClone from '../file/twitter-clone.png';

export const projects = [
    {
        "name": "Twitter Clone",
        "link":"https://github.com/virmon/twitter-clone",
        "desc": "Designed and developed a UI for a twitter-like app. Technologies Used: JavaScript, React, React Hooks, Redux, Material UI",
        "img": `${twitterClone}`
    },
    {
        "name": "Mars Rover Dashboard",
        "link":"https://github.com/virmon/mars-dashboard",
        "desc": "A Mars rover dashboard that consumes the NASA API. The dashboard will allow the user to select which rover's information they want to view. Once they have selected a rover, they will be able to see the most recent images taken by that rover, as well as important information about the rover and its mission. The app makes use of all the functional concepts and practices using pure functions and iterating over, reshaping, and accessing information from complex API responses. Technologies Used: JavaScript, AJAX, NodeJS, HTML, CSS, JSON, Express, ImmutableJS, NPM, Git",
        "img": `${mars}`
    },
    {
        "name": "Shop le Franz Product Catalog",
        "link":"https://shoplefranz.netlify.app/",
        "desc": "Designed and developed product catalog website, enabling user navigation through multiple product categories. Technologies Used: JavaScript, ES6, ReactJS, NodeJS, HTML, CSS, JSON, Express, MySQL, Ant Design, NPM, Git",
        "img": "https://shoplefranz.netlify.app/static/media/logo.9bebebbe.svg"
    },
    {
        "name": "Casting Agency API",
        "link":"https://github.com/virmon/fullstacknd-capstone",
        "desc": "Designed and developed a RESTful API capable of managing different roles of a casting agency, encompassing CRUD services for 3 different roles (Casting Assistant, Casting Director, and Executive Producer) with various access levels. Technologies Used: Python 3, Flask, SQLAlchemy, Postgres, JSON, Git, Postman, Shell, Auth0, Heroku",
        "img": `${fullstacknd}`
    },
    {
        "name": "Reversi Board Game",
        "link":"https://github.com/BGGMVR-Riversi/ASD_Riversi_Game",
        "desc": "Collaborated with 6-member team in design and development of board game. Implemented 3 design patterns: Strategy, Memento, and Iterator, to make the code more flexible and maintainable. Technologies Used: Java, Swing, Postman, Git",
        "img": `${reversi}`
    },
    {
        "name": "Redtomato Design Studio Website",
        "link":"https://redtomatodesignstudio.com",
        "desc": "Designed and developed the website redtomatodesignstudio.com from scratch. Technologies Used: Javasctipt, ES6, ReactJS, HTML, CSS, JSON, NPM, Git, Firebase, Cloudinary",
        "img": "https://res.cloudinary.com/rmkt/image/upload/v1596419637/racheen-website-v2/work/redtomato_website_ug56kn.png"
    },
    {
        "name": "Readable",
        "link":"https://github.com/virmon/reactnd-readable",
        "desc": "Readable is a content and comment web app. Users are able to post content, comment on their post and other's posts and vote on posts and comments. Users may also edit and delete posts and comments. Furthermore, users may sort list of contents by date and by score. Technologies Used: JavaScript, ES6, ReactJS, Redux, HTML, CSS, JSON, NPM, Git",
        "img": `${readable}`
    }
];