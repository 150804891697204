import React from 'react';
import ExternalLink from './ExternalLink';
import './footer.css';

function Footer() {
    return(
        <footer>
            <ExternalLink />
            <p className="copyright">© 2020 Virmon Tumulak</p>
        </footer>
    );
}

export default Footer;