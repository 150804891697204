import React from 'react';
import ProjectList from './components/ProjectList';
import Footer from './components/Footer';
import './App.css';
import Hero from './components/Hero';

function App() {
  return (
    <React.Fragment>
      <Hero />
      <ProjectList />
      <Footer />
    </React.Fragment>
  );
}

export default App;
