import React from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import ExternalLink from './ExternalLink';
import resume from '../file/Virmon-Tumulak-1.pdf';
import me from '../file/me.jpg';

function Hero() {
    return(
        <header className="App-header">
            <a 
                href={resume} 
                target="_blank" 
                rel="noopener noreferrer"
                className="App-link">
                <AiOutlineArrowDown className="arrow-icon"/>
                Resume
            </a>
            <img className="me" src={me} alt="Me" />
            <h1 className="hero-title">Hello, I'm Virmon<span className="divider">.</span></h1>
            <h3 className="hero-subtitle">
                Software Engineer 
                <span className="divider"> | </span>
                Front End React Developer
                <span className="divider"> | </span>
                Graduate Student in Computer Science
            </h3>
            <ExternalLink />
        </header>
    );
}

export default Hero;