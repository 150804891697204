import React from 'react';
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';

function ExternalLink() {
    return(
        <span className="wrapper">
            <a 
                className="external-link" 
                href="https://www.github.com/virmon">
                <AiFillGithub />
            </a>
            <a 
                className="external-link" 
                href="https://www.linkedin.com/in/virmtumulak/">
                <AiFillLinkedin />
            </a>
        </span>
    );
}

export default ExternalLink;