import React from 'react';
import ProjectItem from './ProjectItem';
import './project.css';
import { projects } from '../utils/data';

class ProjectList extends React.Component {
    render() {
        return(
            <div className='project-list'>
                <h1 className='project-title'>Projects</h1>
                {
                    projects && projects.map(project => {
                        return(
                            <ProjectItem 
                                key={project.name}
                                name={project.name} 
                                desc={project.desc}
                                link={project.link}
                                img={project.img} />
                        )
                    })
                }
            </div>
        );
    }
}

export default ProjectList;